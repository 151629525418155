<template>
  <div class="main-box">
    <employer @getFarms="getFarms"/>
    <farms :userId="userId" :userType="userType" :userName="userName" />
  </div>
</template>

<script>
import employer from "./subForm/employer";
import farms from "./subForm/farms";
export default {
  name: "farmuserFarms",
  components: {
    employer,
    farms,
  },
  data() {
    return {
      userId: 0,
      userType: null,
      userName : "لیست تمام زمین های کارفرمایان",
    };
  },
  computed: {},
  methods: {
    getFarms(userId , userType , userName){
      this.userId = userId;
      this.userType = userType;
      this.userName = `لیست تمام زمین های ${userName}`;
    }
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.main-box {
  float: right;
  width: 100%;
}
</style>