<template>
  <div class="employer-box">
    <div class="employer-list">
      <vue-element-loading
        :active="loading"
        text="دریافت لیست کارفرمایان ... "
        spinner="bar-fade-scale"
        color="var(--secondary)"
      />
      <div v-if="partnerList.length > 0">
        <div
          class="info-item"
          :class="selectedEmployer == item.id ? 'employer-selected' : ''"
          v-for="(item, index) of partnerList"
          :key="index"
          @click="getFarmsList(item.id, item.userType, item.fullName)"
        >
          <span>{{ item.typeName }}</span>
          <ul>
            <li>
              <h6 style="font-weight: bold">
                {{ item.fullName }} ({{ item.farmCount }})
              </h6>
            </li>
            <li>
              <h6>{{ item.phone }}</h6>
            </li>
          </ul>
        </div>
      </div>
      <h2
        style="
          font-size: 1.2em;
          width: 100%;
          text-align: center;
          margin-top: 12em;
          color: #bbb;
        "
        v-else
      >
        با هیچ کارفرمایی همکاری نمی کنید
        <i class="fa fa-frown-o" aria-hidden="true"></i>
      </h2>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "userPartnerEmployer",
  data() {
    return {
      loading: false,
      partnerList: [],
      selectedEmployer: 0,
    };
  },
  methods: {
    ...mapActions("connectionFarmuser", ["GetAllPartner"]),
    async fetchData() {
      this.loading = true;
      let resultFarmuser = await this.GetAllPartner({});
      this.loading = false;
      if (resultFarmuser.succeeded) {
        this.partnerList = resultFarmuser.data;
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${resultFarmuser.message}  <br /><br />`,
          type: "error",
        });
      }
    },
    getFarmsList(id, type, userName) {
      this.selectedEmployer = this.selectedEmployer == id ? 0 : id;
      userName = this.selectedEmployer === 0 ? "شما" : userName;
      this.$emit("getFarms", this.selectedEmployer, type, userName);
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style  scoped>
.employer-box {
  float: right;
  width: 25%;
  height: 90vh !important;
}
.employer-list {
  float: right;
  width: 90%;
  height: 86vh !important;
  margin: 5%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 12px -4px #000;
  background-color: #fff;
  border-radius: 9px;
}
.info-item {
  float: right;
  position: relative;
  width: 92%;
  height: 6vh;
  margin: 3vh 1vw 2vh 0px;
  background-color: whitesmoke;
  border-radius: 30px 0px 0px 30px;
  cursor: pointer;
  border: 1px solid #2eb85c;
}
.info-item:hover {
  background-color: #2eb85c;
  color: #fff;
}
.employer-selected {
  background-color: #2eb85c;
}
.employer-selected ul li h6 {
  color: #fff;
}
.info-item span {
  float: right;
  width: 5vw;
  height: 9vh;
  background-color: #2eb85c;
  text-align: center;
  padding-top: 3vh;
  color: #fff;
  border-radius: 50px;
  position: absolute;
  top: -1em;
  right: -1em;
  font-size: 1em;
}
.info-item ul {
  float: right;
  margin: 0px;
  padding-right: 4vw;
  list-style: none;
}
.info-item ul li {
  float: right;
  margin: 4px 1vw;
}
.info-item ul li h6 {
  float: right;
  margin: 1px 7px;
  font-size: 0.9em;
}
</style>
